import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from '../components/SEO';

const StyledWrapper = styled.div`
  margin-top: 150px;
`;

const StyledPageTitle = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: ${({ theme }) => theme.font.size.s};
  text-align: center;
  text-transform: uppercase;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledGallery = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2rem 6rem 6rem 6rem;
`;

const GalleryItems = styled.div`
  display: grid;
  width: 100rem;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  align-items: start;
`;

const GalleryItem = styled(Link)`
  position: relative;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  text-align: center;
`;

const StyledImg = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 30rem;
  object-fit: cover;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }

  ${GalleryItem}:hover & {
    filter: brightness(60%);
  }
`;

const StyledCaption = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3em;
  display: block;

  &:before {
    position: absolute;
    content: '';
    opacity: 1;
    top: 50px;
    right: 30px;
    bottom: 50px;
    left: 30px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    transform-origin: 0 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: scale(1);

    ${({ theme }) => theme.mq.desktop} {
      transform: scale(0, 1);

      ${GalleryItem}:hover & {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    opacity: 1;
    top: 30px;
    right: 50px;
    bottom: 30px;
    left: 50px;
    border-right: 2px solid white;
    border-left: 2px solid white;
    transform-origin: 100% 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: scale(1);

    ${({ theme }) => theme.mq.desktop} {
      transform: scale(1, 0);

      ${GalleryItem}:hover & {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.15s;
      }
    }
  }
`;

const StyledCaptionTitle = styled.h3`
  opacity: 1;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 1px;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  top: 50%;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.35s, transform 0.35s;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    transform: translateY(-50%) !important;
    opacity: 0;

    ${GalleryItem}:hover & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-delay: 0.15s;
    }
  }
`;

export default function GalleryPage({ data }) {
  return (
    <>
      <SEO title="Galeria" />
      <StyledWrapper>
        <StyledPageTitle>Zobacz nasze relizacje</StyledPageTitle>
        <StyledGallery>
          <GalleryItems>
            {data.offers.nodes.map((item) => (
              <GalleryItem to={`/galeria/${item.slug.current}`} key={item.id}>
                <StyledImg image={item.image.asset.gatsbyImageData} alt={item.title} />
                <StyledCaption>
                  <StyledCaptionTitle>{item.title}</StyledCaptionTitle>
                </StyledCaption>
              </GalleryItem>
            ))}
          </GalleryItems>
        </StyledGallery>
      </StyledWrapper>
    </>
  );
}

export const query = graphql`
  query OffersQuery {
    offers: allSanityOffer {
      nodes {
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
        id
        listOfOffers
        slug {
          current
        }
        title
        headline
        description
      }
    }
  }
`;
